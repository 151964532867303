@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.label {
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  width: 100%;
  padding: 20px 20px 20px 77px;
  margin: 0;
  border-radius: 8px;
  background: var(--shape-20);

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.toggle {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}

.infoTitle {
  @include font-style-small;
  color: var(--text-10);
}

.infoDescription {
  @include font-style-tiny;
  margin: 0;
  color: var(--text-20);
}
